.scrollable-container {
  height: calc(100vh - var(100px));
    /* height: 100vh; Full height of the viewport */
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar (for Firefox) */
    -ms-overflow-style: none; /* Hide scrollbar (for Internet Explorer) */
  }
  
  .scrollable-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar (for WebKit browsers) */
  }
  