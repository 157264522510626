/* Container for the grid */
.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensure no more than 2 items per row */
    gap: 3px; /* Space between rows */
    max-width: 420px; /* Maximum width of the grid */
    width: 100%; /* Allow the grid to expand to full width up to max-width */
    margin:  auto; /* Center the grid horizontally */
  }
  
  .grid-item {
    text-align: center;
    position: relative;
    /* z-index: 1300; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .grid-item img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 0px;
    object-fit: cover;
  }
  
  .grid-item .placeholder {
    width: 100%;
    aspect-ratio: 1;
    border: 1px dashed #ccc;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /* color: #888; */
    margin-bottom: 0px;
  }
  
  .grid-item input[type="text"] {
    margin-top: 0px;
    width: 100%;
    border-color:lightgray;
    border-style: solid;
    border-width: 1px;
    padding: 0px 2px 0px 2px;
    text-align: left;
    margin-bottom: 2px;
  }
  
  .grid-item .button-container {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    gap: 4px;
  }
  
  .grid-item .button-container button {
    background: blue;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .grid-item .button-container button.delete {
    background: red;
  }
  
  .add-button {
    margin-bottom: 8px;
    padding: 4px 8px;
    font-size: 12px;
    background: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:disabled {
    background: gray;
    cursor: not-allowed;
  }

.dragged {
  background-color: rgba(0, 123, 255, 0.1);
  outline: 1px  #007bff;
  transform: scale(1.05);
  /* transition: transform 0.2s ease; */
  z-index: 1000; /* Ensure the dragged item is above other elements */
}
  
  /* Fullscreen overlay */
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1301;
    cursor: pointer;
  }
  
  .fullscreen-image {
    max-width: 430px;
    max-height: 100%;
    padding: 3px;
    border-radius: 2px;
    z-index: inherit;
  }
  
  /* Thumbnail images */
  .image-thumbnail {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
    margin-bottom: 0px; /* Reduce space between image and text to 0px */
  }

  .delete {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .delete.disabled {
    color: gray;
    cursor: not-allowed;
  }
  
  .delete:disabled {
    opacity: 0.5;
  }


  .input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .input-container input {
    width: 100%;
    padding-right: 24px; /* Space for the icon */
  }
  
  .input-container .delete-icon {
    position: absolute;
    right: 3px;
    cursor: pointer;
    color: #ff4d4d; /* Red color for delete */
  }
  
  .input-container .delete-icon:hover {
    color: #ff0000; /* Darker red on hover */
  }
  
  .choice-header {
    padding: 1px;
    word-break: break-word;
    white-space: normal;
    text-align:center;
    align-self: flex-end;
    font-size: 0.8em;
    font-weight: normal;
  }

  .dragged-outline {
    outline: 2px dashed #007bff; /* Blue dashed outline */
    background-color: rgba(0, 123, 255, 0.1); /* Light blue background for better visibility */
    border-radius: 4px; /* Optional: round the corners slightly */
  }