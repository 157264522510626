
img {
    object-fit: cover;
}

.btn-file {
    position: relative;
    overflow: hidden;
    vertical-align: middle; }
  
  .btn-file > input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 23px;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    direction: ltr; }
  
  .fileinput {
    text-align: center;
    display: inline-block;
    margin-bottom: 9px; }
    .fileinput input[type="file"] {
      display: none; }
  
  .fileinput .form-control {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 5px;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: text; }
  
  .fileinput .thumbnail {
    display: inline-block;
    margin-bottom: 0px;
    overflow: hidden;
    text-align: center;

    vertical-align: middle;
    max-width: 400px;
    max-height:300px;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
    
     .thumbnail.img-circle {
      border-radius: 50%;
      width: 110px;
      height:110px;
      display:flex;
      justify-content: center; 
    }
  
  .fileinput .thumbnail > img {
    max-height: 100%;
    width: 100%; }
  
  .fileinput .btn {
    vertical-align: middle; }
  
  .fileinput-exists .fileinput-new,
  .fileinput-new .fileinput-exists {
    display: none; }
  
  .fileinput-inline .fileinput-controls {
    display: inline; }
  
  .fileinput-filename {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle; }
  
  .form-control .fileinput-filename {
    vertical-align: bottom; }
  
  .fileinput.input-group {
    display: table; }
  
  .fileinput.input-group > * {
    position: relative;
    z-index: 2; }
  
  .fileinput.input-group > .btn-file {
    z-index: 1; }
  
  .fileinput-new.input-group .btn-file,
  .fileinput-new .input-group .btn-file {
    border-radius: 0 4px 4px 0; }
  
  .fileinput-new.input-group .btn-file.btn-xs,
  .fileinput-new .input-group .btn-file.btn-xs,
  .fileinput-new.input-group .btn-file.btn-sm,
  .fileinput-new .input-group .btn-file.btn-sm {
    border-radius: 0 3px 3px 0; }
  
  .fileinput-new.input-group .btn-file.btn-lg,
  .fileinput-new .input-group .btn-file.btn-lg {
    border-radius: 0 6px 6px 0; }

    div.show.dropdown-menu.imagemenu{
      min-width: 70px;
      padding: 0;
      margin:0;
    }
  
    div.popover-enter-done.imagemenu{
      padding:0;
      margin:0;
      width:70px;
    }
  
    button.dropdown-item.imagemenu{
      padding:0;
      margin:0;
      width:70px;
    }  

    .main-image{
      width:100%;
      object-fit: contain;
    }

    .card-image{
      width:300;
      height:207;

    }

.smedit{
  font-size: .9em;
  line-height: 1.2em;
  overflow-y: hidden;
  overflow-x: hidden;
}

.icon-caption{
    font-size: .85em;
    margin-top: 0px;
    padding: 2px 5px;
  }

.sameresponse
{
    font-size: 1.2em;
}


.iconSize{
    font-size: 1.5rem;
}
