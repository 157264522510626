
  .modalHeader{
    font-size: .85em;
  }

  .minHeight{
    min-height:100px;
  }

  .tagFeedHeight{
    min-height:300px;
  }
  
  .modal-overlay, .question-modal-overlay{
    position: absolute;
    right: 0;
    bottom: 0;
    top: 50%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    /* top: '50%';
    left: '50%'; 
    transform:'translate(-50%, -50%)';*/
    justify-content: center;
    align-items:center;
  }

  .qmodal {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    margin:2px;
     max-width: 380px;
  }

  .question-modal-overlay{
    z-index: 0;
    top: -25%;
  }

  .modal-overlay{
    z-index: 1;
    /* top: -50%; */
  }

  .errortext{
    color: red;
    text-decoration: underline;
}