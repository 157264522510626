/* .chatlist {
    list-style: none;
    margin: 0;
    padding: 0;
  }
 
  .chatItem{
      margin-bottom:2px;
     display:inline-block;
    clear: both;
  }  */
   
  
  .w-85{
      max-width:85%;
  }

  .w-45{
    width:35px;
}
  
  .chatName{
    padding-left:40px;
  }

  .pane {
    height: calc(100% - 0px);
    overflow: hidden;
    overflow-y: auto; }
    
.them{
    background-color: darkslategray;
  }

  .me{
    background-color: indigo;
    text-align: right;
  }

  .them, .me{
    padding: 4px 10px 4px 10px;
    border-radius: 12px;
    margin-top: 1px;
    color:white;
    font-family: "Plus Jakarta Sans";
    font-size: .95rem;
  }
  
  .hidden{
    display:none;
  }
  .textLabel{
    border:0;
  }

  .imageBtn{
    position: absolute; bottom:0; left:0;
  }
  .chatImage { position: relative; }
  .container img { display: block; }
  .chatImage .fa-times-square { position: absolute; top:0; right:0; margin: 10px; }


.chatImage  img {
    height: auto;
    width:auto;
    border-radius:10px;
    margin-bottom:1px;
    margin-top:2px;
    max-height: 300px;
    max-width: 250px;
} 

.chatmessages
{
    overflow:   auto;
    position:   absolute;
    bottom:     0;
}

.listBottom{
    height:2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.listBottomx::-webkit-scrollbar {
  display: none;
  height:1px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.listBottomx {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height:1px;
}
