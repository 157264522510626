/* .active{
    cursor: hand;
} */

.question {
    line-height: 100%;
    font-size:larger;
}

/*  Image editor display controls */
div.hover-div{
    display:block;
    position:relative;
    cursor:default;

}


div.hover-div:hover .hover-text {
    display:block;
}

div.hover-div:hover {
    display:block;
    color: blue;
    opacity:1;
}

div.hover-div:hover img{
    opacity:0.3;
}

.hover-text{
    clear:both;
    background: rgba(68, 67, 67, 0.5);
    color:white;
    display: hidden;
    position:absolute;
    cursor: pointer;
    z-index:2;
    padding:2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hover-div i {
    position: absolute;
    color:blue;
    cursor:pointer;
    top: -10px;
    right:-10px;
    z-index:2;
}

.hover-div:hover i {
    color:blue;
}




.image-item {
    width: 100%;
    height: 100%;
  }
  ul, li {
    list-style-type: none;
  }

.lyq-btn{
   width:58px; 
   height: 23px;
   margin-top:3px;
   margin-bottom:3px;
}

.centerBuffer{
  height:38px;
}

.lyqPopover {
    max-width: 300px;
  }
     
.tagPopover {
  max-width: 300px;
}


.popover-header{
    color:black;
    font-size:1.3em;
    padding:3;
}  

.popover-body{
    color:black;
  font-size:1.1em;
  padding:3;
}

.popover-item{
  padding: 8px 10px;
  cursor:pointer;
}

.parent{
    width: 100%;
    height: 100%;
    overflow: hidden;
}


  .scrollbar {
 min-height: 100vh;
  overflow-y:auto;
  overflow-x:hidden;
  scrollbar-width:none;
}

.scrollable-container {
  height: 100vh; /* Full height of the viewport */
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar (for Firefox) */
  -ms-overflow-style: none; /* Hide scrollbar (for Internet Explorer) */
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar (for WebKit browsers) */
} 

.my-scrollBar{
  overflow: hidden;
}

 .scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}


.scrollbar-primary::-webkit-scrollbar {
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285F4;
}   

.same-dot{
  font-size: 2em;
  line-height: .2em;
  position:relative;
  top: 2px;
  right:2px;
  color: indigo;
}

.same-num{
  font-size: .9em;
  padding-top: .5em;;

}

.scrolling{
    max-height: min-vh-100;
}

.follow {
  width:65px;
}

.middleThing{
    height:35px;
}

.responseCount{
    font-size:13px;
    width:35px;
}

.bottomScrollAdjustment{
    height: 200px !important;
  }

  .questiontext, .questionText{
    font-size: 1.1em;
    line-height: 1.4em;
  }

  .bigquestiontext, .bigQuestionText{
    font-size: 1.2em;
    line-height: 1.3em;
  }  

  .smallball{
    height: 23px;
    width: 23px;
    font-size: .8em;
    margin-right: 4px;
    border:1px lightgray;
    border-style: solid;
}
.smallresult{
    border:1px lightgray;
    border-style: solid;
    padding: 1px 2px 1px 2px;
}

.linkPadRight{
  padding-right: 3px;
}
