.card-timeline .timeline {
    list-style: none;
    padding: 5px 0 5px;
    position: relative; 
    }
    /**  the vertical line 
    .card-timeline .timeline:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 0px;
      background-color: #dbdbdb;
      left: 50%;
      margin-left: -1.5px; }**/
    .card-timeline .timeline .timeline-footer .btn {
      margin: 0; }
    .card-timeline .timeline .timeline-footer .dropdown {
      display: inline-block; }
    .card-timeline .timeline h6 {
      color: gray;
      font-weight: 400;
      margin: 10px 0px 0px; }
    .card-timeline .timeline.timeline-simple:before {
      left: 5%; }
    .card-timeline .timeline.timeline-simple > li > .timeline-panel {
      width: 86%; }
    .card-timeline .timeline.timeline-simple > li > .timeline-badge {
      left: 5%; }
    .card-timeline .timeline > li {
      margin-bottom: 15px;
      position: relative; }
      .card-timeline .timeline > li:before, .card-timeline .timeline > li:after {
        content: " ";
        display: table; }
      .card-timeline .timeline > li:after {
        clear: both; }
    /**   the text box  **/
    .card-timeline .timeline > li > .timeline-panel {
      width: 45%;
      float: left;
      padding: 10px;
      border-radius: .5rem;
      /* box-shadow: 2px 0px 2px rgba(204, 197, 185, 0.5); */
      background-color: #ffffff;
      color: #2c2c2c;
      margin-bottom: 0px;
      position: relative; }
      /** the pointer to the left  **/
      .card-timeline .timeline > li > .timeline-panel:before {
        position: absolute;
        top: 23px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #e3e3e3;
        border-right: 0 solid #e3e3e3;
        border-bottom: 15px solid transparent;
        content: " "; }
      /** inside the left pointer  **/
      .card-timeline .timeline > li > .timeline-panel:after {
        position: absolute;
        top: 23px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid white;
        border-right: 0 solid white;
        border-bottom: 14px solid transparent;
        content: " "; }
    /** badges where images are currently at  **/
    .card-timeline .timeline > li > .timeline-badge {
      color: #ffffff;
      width: 40px;
      height: 40px;
      line-height: 51px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 10px;
      left: 50%;
      margin-left: -25px;
      z-index: 1;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%; }
      .card-timeline .timeline > li > .timeline-badge [class^="ti-"],
      .card-timeline .timeline > li > .timeline-badge [class*=" ti-"] {
        line-height: inherit; }
      .card-timeline .timeline > li > .timeline-badge .now-ui-icons {
        line-height: 2.6;
        width: 25px;
        text-align: center; }
    .card-timeline .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
      background-color: #fff; }
      .card-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto; }
      .card-timeline .timeline > li.timeline-inverted > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto; }
  
  .card-timeline .timeline-heading {
    margin-bottom: 15px; }
  
  .card-timeline .timeline-badge.primary {
    background-color: #2ca8ff !important; }
  
  .card-timeline .timeline-badge.info {
    background-color: #2ca8ff !important; }
  
  .card-timeline .timeline-badge.success {
    background-color: #18ce0f !important; }
  
  .card-timeline .timeline-badge.warning {
    background-color: #ffb236 !important; }
  
  .card-timeline .timeline-badge.danger {
    background-color: #ff3636 !important; }
  
  .card-timeline .timeline-title {
    margin-top: 0;
    color: inherit; }
  
  .card-timeline .timeline-body > p,
  .card-timeline .timeline-body > ul {
    margin-bottom: 0; }
  
  .card-timeline .timeline-body > p + p {
    margin-top: 5px; }

    .LyqnessProfileSelector{
        height: 50px;
    }