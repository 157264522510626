  #example{
    background-repeat: repeat-x;    
    margin-left: 20px;
    margin-top: 10px;
}

.bigAvatar {
  width: 80;
  height: 80;
}


.ballfont {   
    font-size: .8rem;
}

.main-image{
    /* max-height: 300px;
    max-width:400px; */
    width:100%;
    object-fit: contain;
  }

  .align-bottom{
    vertical-align: bottom;
  }