body::-webkit-scrollbar {
    display: none;
    overflow-x: hidden;
    overflow-y: hidden
}

html,
body,
#root,
.App {
    font-family: "Plus Jakarta Sans";
    height: 100%;
    color:brand5;
    overflow: hidden;
}

@media screen and (max-width: 400px) {
    html,
    body,
    #root,
    .App {
        font-size: 98%;
    }
  }

.btn:focus {
    outline: none;
    box-shadow: none;
  }

  .btn:hover {
    background-color: lightblue;
    outline: none;
    box-shadow: none;
  }

.fill {
    min-height: 100%;
    height: 100%;

}



.full-height {
    height: calc(50vh);
}

.headerObjects{
    margin-top: -20px;
}

.gray{
    color:gray;
}

.flyout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* justify-content: space-between; */
}

.new-item-dot {
    font-size: 8px;
    position: relative;
    left: -1px;
    top: -2px
  }
  
.home-feature-box .fa {
    font-size: 6rem;
}

.footerlabel{
    font-size: .70em;
}

.home-feature-box span {
    display: block;
    color: #111;
    font-weight: bold;
    margin-top: 1.5rem;
}

.navbar {
    margin-bottom: 0;
    padding-bottom: 0;
    z-index: 1301
}

.link {
    font-size: 14px;
    color: blue;
}

.info {
    font-size: 14px;
    color: black;
}

.username {
    font-size: 16px;
    font-weight: bold;
    color: rgb(8, 85, 185);
}

.textBorder{
    border-radius: 10px;
}

.questionHeader-bg{
    background-color: rgb(248, 248, 248);
}

/* .panelHeader{
    display:flex;
    height:45px;
    min-width:360px;
    max-width: 430px !important;
} */
 
.lyqcard, .panelcard, .questioncard, .profilecard {
    max-width: 430px;
    min-width: 360px; 
    width:100%;
    overflow: hidden;
}

.profileCard, .questioncard{
    background-color: white;
    border-radius: 10px !important;
    border: 1px solid #cccccc !important;
}

.profileCard{
    margin-bottom: 3px;
    margin-left: 2px;
    margin-right: 2px;
}

.questioncard{
    margin-bottom: 14px;
}

 .rightpanel{
    min-width:360px;
    max-width: 430px !important;
 }

 .panelHeader{
    height:45px;
 }

.activeColor{
    color:blue !important;
}

.inactiveColor{
    color:lightsteelblue !important;
}

.activeBackgroundColor{
    background-color:#0066ff !important;
}

.inactiveBackgroundColor{
    background-color:#cccccc !important;
    color:#6d7071 !important;
}

.passiveButton{
    background-color:rgb(134, 246, 249) !important;
    border-color: blue;
    color:black !important;
}

.infoCard{
    border-radius: 15px;
    border-color: #2e4971ff;
    border-width: 3px;
    width:330px;
}
.similarity {
    color: #189ad6 !important;
}

.similarity-bg{
    background-color: #35678c !important;
}

.clearfix{
    clear:both;
}

.smallfont{
    font-size: .85em;
}

.choicefont{
    font-size: .85em;
}

.brand0{
    color:#189ad6 !important;
}

.brand1{
    color:#2e4971ff !important;
}

.brand2, .panelHeader-text{
    color:#f5f5f5 !important;
}

.brand3, .panelAction {
    color:#cccccc !important;
}

.brand4, .time-text {
    color:#6d6e71 !important;
}

.brand5{
    color:#1d1d1d !important;
}

.brand6{
    color:#1d1d1d !important;
}

.brand7{
    color:#9a7bf7 !important;
}

.brand0-bg{
    background-color:#189ad6 !important;
}

.brand1-bg, .panelHeader-bg{
    background-color:#35678c !important;
}

.brand2-bg{
    background-color:#f5f5f5 !important;
}

.brand3-bg{
    background-color:#cccccc !important;
}

.brand4-bg{
    background-color:#6d6e71 !important;
}

.brand5-bg{
    background-color:#001433 !important;
}

.brand6-bg{
    background-color: #1d1d1d !important;
}

.white {
    background-color: white;
}

.gold{
    background-color: gold !important;
    color:black;
}

.yellow {
    background-color: yellow;
}

.orange {
    background-color: orange;
}

.blue {
    background-color: blue !important;
}

.green {
    background-color: green;
}

.error-text {
    color: red !important;

}

.white-text {
    color: white !important;
}

.black-text {
    color: black !important;
}

.time-text {
    font-size: .9rem;
}

.follow-text {
    font-size: .9rem;
    margin: -5px 0px 0;
}

.pointer {
    cursor: pointer;
}

.roundedBorder{
    border-radius: 5px !important;
}

.userselected{
    color:#0000EE !important;
}

.noneselected{
    color:lightgray;
}

.silverbgcolor {
    background-color: rgb(241, 241, 241) !important;
    color: grey!important;
}

.disabledcolor {
    color: rgb(200, 200, 200) !important;
}

.capitalize {
    text-transform: none !important;
    /*For Lower case use lowercase*/
}

.chatHeight {
    height: 600px;
}

.menusize {
    font-size: 25px;
}

.bg1 {
    border-color: red;
}

.miniBall {
    width: 25px;
    height: 25px;
}

.lotteryResult {
    line-height: 1;
    position: relative;
    font-family: "Plus Jakarta Sans";
    bottom: 25%;
}

.ballNumber {
    font-size:1.1rem;
    margin: 3px;
    padding-top: 2px;
    /* margin-top: 0; */
}

.new-dot {
    font-size: 40px;
    padding-left: 0;
    position: relative;
    top: 0px;
    right: 7px;
    color: #f3f4fa;
}

.tooltipLabel {
    text-wrap: nowrap;
    white-space: nowrap;
}

.tagContainer{
    flex-wrap: wrap;  
}

.tag{
    padding-right: 5px;
    cursor:pointer;
    flex-wrap: wrap;
    display:inline-block;
}



.mymodal {
    top: 40px;
    max-width: 430px !important;
    width:430px;
}

.modalHeaderColor{
    background-color: #5fccff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}


.activityCard {
    width: 300px;
    overflow-x: hidden;
}

*:focus {
    outline: none important!;
}

.topBorder, .bottomBorder{
    border-radius: 0px !important;
}



.fa-linkedin{
  color: #0976b4; 
} 

.fa-facebook{
    color: #3b5998; 
  } 

  .statLabel,.feedLabel {
    font-size: 12px;
    letter-spacing: 0.02em;
    overflow: hidden;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out 0s;
}
.feedLabel{
    font-size: 16px;
}

.respondLabel{
    font-size: .8em;
    letter-spacing: 0.01em;
}

.nameFont {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
}

.avatarImg{
    position:relative;
    top: -55;
}
.profileAvatar {
    float: left;
    margin: -65px 0px 0 8px;
}
.lyqnessAvatar{
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

.description{
    font-style: italic;
    height:40px
}

.twPc-button {
    margin: -45px -10px 0;
    text-align: right;
    width: 100%;
}

.tagfont{
    font-size:11pt;
  }

  .tagcount{
    font-size:10pt;
    line-height: 1.5em;;
  }